import React from 'react'
import { BasePage } from 'cng-web-lib'
import TablePage from './TablePage.js'
import useBreadcrumbNameMap from 'src/useBreadcrumbNameMap'

function WrappedTablePage(props) {
    const breadcrumbNameMap = useBreadcrumbNameMap()
    return (
        <BasePage
            renderPage={(showSnackbar) => (
                <TablePage showNotification={showSnackbar} {...props} />
            )}
            extraCngBreadcrumbNameMap={breadcrumbNameMap} />
    )
}

export {
    WrappedTablePage as TablePage
}
