import React, { useEffect, useState } from 'react'
import { components } from 'cng-web-lib'
import { FormControl, Grid, Select, Paper, Box, InputLabel } from '@material-ui/core'

const {
    button: { CngButton },
    CngDialog
} = components

function ChargeTypeDialog(props) {
    const { chargeTypes, onClose, onSubmit, open, items } = props
    return (
        <CngDialog
            dialogContent={
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <ChargeTypeItem
                            chargeTypes={chargeTypes}
                            items={items}
                        />
                    </Grid>
                </Grid>
            }
            dialogAction={
                <>
                    <CngButton color='secondary' onClick={onClose} size='medium'>Back</CngButton>
                    <CngButton
                        color='primary'
                        onClick={() => {
                            onSubmit(items)
                            onClose()
                        }}
                        size='medium'
                    >Submit to customs</CngButton>
                </>
            }
            dialogTitle='Charge As'
            fullWidth
            maxWidth='md'
            onClose={onClose}
            open={open}
            shouldShowCloseButton
        />
    )
}

export default ChargeTypeDialog

function ChargeTypeItem(props) {
    const { chargeTypes, items } = props
    const [chargeAs, setChargeAs] = useState('XFEE')

    useEffect(() => {
        if (items instanceof Array) {
            items.forEach((item) => {
                item.chargeAs = chargeAs
            })
        } else {
            items.chargeAs = chargeAs
        }

    }, [chargeAs])

    return (
        <Paper variant='outlined'>
            <Box padding={2}>
                <Grid item xs={12}>
                    <FormControl variant='outlined' fullWidth>
                        <InputLabel htmlFor='submit-item-dialog-charge-type'>Charge As</InputLabel>
                        <Select
                            label='Charge As'
                            inputProps={{ id: 'submit-item-dialog-charge-type' }}
                            native
                            onChange={(event) => setChargeAs(event.target.value)}
                            size='small'
                            value={chargeAs}
                        >
                            {chargeTypes.map((option) => {
                                return (
                                  <option key={option.code} value={option.code}>
                                    {option.desc}
                                  </option>
                                );
                                })}
                        </Select>
                    </FormControl>
                </Grid>
            </Box>
        </Paper>
    )
}