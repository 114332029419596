import { Grid } from '@material-ui/core'
import React from 'react'
import { components, useTranslation } from 'cng-web-lib'
import ArrivalEditForm from './ArrivalEditPage'
import ArrivalResponse from './ArrivalResponse'
import { useParams } from 'react-router-dom'
import Namespace from 'src/constants/locale/Namespace'
import AuditLog from './AuditLog.js'
import ArrivalKeys from 'src/constants/locale/key/Arrivalmessage';
import withFormState from 'src/components/aciacehighway/hocs/withFormState'

const { CngTabs } = components


function EditPage({ showNotification, onSetLoading, history }) {
  const { id } = useParams()
  const { translate } = useTranslation([Namespace.ARRIVALMESSAGE])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let arrivalmessage = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalKeys.TITLE
    )
    let arrivalResponse= translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalKeys.ArrivalResponse.TITLE
    )

    return {
      arrivalmessage,
      arrivalResponse
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngTabs
          headerColor='primary'
          tabs={[
            {
              tabName: translatedTextsObject.arrivalmessage,
              tabContent: (
                <ArrivalEditForm
                  showNotification={showNotification}
                  id={id}
                  onSetLoading={onSetLoading}
                  history={history}
                />
              )
            },
            {
              tabName: translatedTextsObject.arrivalResponse,
              tabContent: (
                <ArrivalResponse
                  showNotification={showNotification}
                  arrivalId={id}
                  isViewOnly={true}
                />
              )
            },
            {
              tabName: "Activity Log",
              tabContent: (
                <AuditLog
                  showNotification={showNotification}
                  arrivalId={id}
                   isViewOnly={true}
                />
              )
            }
          ]}
        />
      </Grid>
    </Grid>
  );
}

export default withFormState(EditPage)