import makeValidationSchema from './ArrivalMessageSNDTOsMakeValidationSchema'
import { useTranslation } from 'cng-web-lib'
import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import ArrivalmessageKeys from 'src/constants/locale/key/Arrivalmessage'
import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
} from 'cng-web-lib'

const {
  card: {
     CngSimpleCardHeader,
  },
  form: {
    field: {
      CngTextField,
      CngCodeMasterAutocompleteField
    },
  },
   CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  email: "",
  phno: "",
  snTyp: ""
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({
  disabled,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap
}) {
  const { translate } = useTranslation(Namespace.ARRIVALMESSAGE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let arrivalMessageSNDTOs = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.ArrivalMessageSNDTOs.TITLE
    )
    let email = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.ArrivalMessageSNDTOs.EMAIL
    )
    let phno = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.ArrivalMessageSNDTOs.PHNO
    )

    let snTyp = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.ArrivalMessageSNDTOs.SNTYP
    )

    return {
      arrivalMessageSNDTOs,
      email,
      phno,
      snTyp
    }
  }

	return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.arrivalMessageSNDTOs} />
      <CardContent>
        <Grid container spacing={3}>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.email}>
            <CngTextField
              name="email"
              inputProps={
                {maxLength: 250}
              }
              label={translatedTextsObject.email + " *"}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.phno}>
            <CngTextField
              name="phno"
              inputProps={
                {maxLength: 15}
              } 
              label={translatedTextsObject.phno}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.snTyp}>
           <CngCodeMasterAutocompleteField
                  name="snTyp"
                  label={translatedTextsObject.snTyp + " *"}
                  disabled={disabled}
                  codeType='SYSTEM_STATUS'
            />
        </CngGridItem>
        </Grid>
      </CardContent>
    </Card>
	)
}

const ArrivalMessageSNDTOsFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default ArrivalMessageSNDTOsFormProperties
