import React from 'react'
import { Tooltip, makeStyles, Typography, Box } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  tooltip: {
    color: theme.palette.primary.main,
    maxWidth: 450
  }
}))

function createHelperText(text) {
  let split = text.split('<br/>')
  let initial = true

  let content = []

  split.forEach((e) => {
    if (!initial) {
      content.push(<br />)
    }

    content.push(<Typography variant='body2'>{e}</Typography>)
    initial = false
  })

  return content
}

function HelperText(props) {
  const { helperText, placement } = props
  const classes = useStyles()

  return (
    <Tooltip
      placement={placement ? placement : 'bottom-start'}
      title={<Box padding={1}>{createHelperText(helperText)}</Box>}
      className={clsx(classes.tooltip, 'section-helper-text')}
      arrow
    >
      <span>
        <FontAwesomeIcon
          icon={['fal', 'circle-question']}
          size='lg'
          color='inherit'
        />
      </span>
    </Tooltip>
  )
}

export default HelperText
