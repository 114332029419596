import { components,useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { useFormContext } from "react-hook-form";
import { DataFlattener,useServices } from 'cng-web-lib'
import { Button, ButtonGroup, Grow, Paper, Popper, MenuItem, MenuList, ClickAwayListener } from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ManifestKeys from 'src/constants/locale/key/AciHighwayManifest'


const {
  form: {
    adapter: {
      useFormAdapter:{ useFormikContext }
    } 
  },
  button: { CngPrimaryButton, CngSecondaryButton },
  CngGridItem, CngDialog
} = components


function CustomButtonSection({ buttonLabel, url, data, isAdd, showNotification, redirect, isAttach, isUpload, manifestId, chargeTypeRequired, isSuperParty }) {

  const { getValues, trigger, formState: { isSubmitting } } = useFormContext(); 

  const { translate } = useTranslation(Namespace.ACIHWY_MANIFEST)
  const translatedTextsObject = makeTranslatedTextsObject()

  const {securedSendRequest } = useServices();

  function makeTranslatedTextsObject() {
    let attach = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.ATTACH_BUTTON
    )
    let upload = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.UPLOAD_BUTTON
    )
    let submitRecord = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.SUBMIT_RECORD
    )
    let submitSuccessMsg= translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.SUBMIT_SUCCESS_MSG
    )
    let submitOneRecordAlert= translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.SUBMIT_ONEREC_ALERT
    )
      

    return{
      attach,
      upload,
      submitRecord,
      submitSuccessMsg,
      submitOneRecordAlert
    }
  }
  
  //Get the current modified form data using Formik
  var values = getValues();
  if (!isAdd) {
    //Get the saved form Data from Server
    var serverData = data.current
    
    //Copy the modified data in form to Server Data - this ensures ID and other basic information is copied
    if(serverData.inbondDateString === undefined || serverData.inbondDateString === null || serverData.inbondDateString === ''){ //For Ace Highway Inbond Arrival
      values = { ...serverData, ...values }
    }else{
      values = { ...serverData }
    }
  }

  const [openDialog, setOpenDialog] = useState() 
  const [openSplitButton, setOpenSplitButton] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [anchorRef, setAnchorRef] = useState(null);
  const history = useHistory()
  const { id } = useParams() 

  const splitButtonDisplay = ['Regular User', 'XFEE/Fax User', 'Support'];
  const splitButtonValue = ['REGULAR', 'XFEEE', 'SUPPORT'];

  const handleButtonClicked = () => {
    values.chargeAs = splitButtonValue[selectedIndex];
    handleSubmit();
  };

  const handleButtonSelected = (event, index) => {
    setSelectedIndex(index);
    setOpenSplitButton(false);
  };

  const handleToggleSplitButton = (event) => {
    setAnchorRef(event.currentTarget)
    setOpenSplitButton((prevOpen) => !prevOpen)
  };

  const buttonStyle = {
    backgroundColor: '#5e81f4',
    color: '#ffffff',
    textTransform: 'none'
  };

  const redirectToSearch=()=>{
    history.push({
      pathname: '/aci-highway/cargo',
      state: {  // location state
        id: id, 
      },
    }); 
  } 

  const redirectToCSVUpload=()=>{
    history.push('/aci-highway/cargo/upload',manifestId)
  }
  
  const handleSubmit = () => {
    if (chargeTypeRequired && !isSuperParty) {
      values.chargeAs = 'REGULAR'
    }

    function onSuccess(response) {
      var data = response.data
      var errorMessages = data.errorMessages
      if (response.status == 200 && data != null && errorMessages == null) {
        showNotification("success", translatedTextsObject.submitSuccessMsg)
        history.push(redirect)
      } else {
        showNotification("error", errorMessages)
      }
    }

    function onError(error) {
      console.log(error);
    }

    function onComplete() {
    }

    const config = {}
    securedSendRequest.execute('POST', url, DataFlattener.unflatten(values), onSuccess, onError, onComplete, config, null);

    setOpenDialog(false);
  }

  return (
    <CngGridItem>    
      <CngPrimaryButton disabled={isSubmitting} isSubmitting={isSubmitting} onClick={() => {
        trigger().then(
          (result)=>{
            if(result){
              setOpenDialog(true)
          }
        });
      }} 
      >
        {buttonLabel}
      </CngPrimaryButton>
      <span>
      {(isAttach) && (
      <CngPrimaryButton style={{ marginLeft: '1.875rem' }} disabled={isSubmitting} onClick={redirectToSearch}>{translatedTextsObject.attach}</CngPrimaryButton>
      )}
      </span>
      <span>
      {(isUpload) && ( 
      <CngPrimaryButton style={{ marginLeft: '1.875rem' }} disabled={isSubmitting} onClick={redirectToCSVUpload}>{translatedTextsObject.upload}</CngPrimaryButton>
      )}
      </span>
      <CngDialog 
        dialogAction={
          <>
            <CngSecondaryButton onClick={() => { setOpenDialog(false) }}>Close</CngSecondaryButton>
            {(chargeTypeRequired && isSuperParty) && (
              <>
                <ButtonGroup>
                  <Button onClick={handleButtonClicked} style={{ ...buttonStyle }}>Submit As {splitButtonDisplay[selectedIndex]}</Button>
                  <Button size="small" onClick={handleToggleSplitButton} style={{ ...buttonStyle }}><ArrowDropDownIcon /></Button>
                </ButtonGroup>

                <Popper anchorEl={anchorRef} open={openSplitButton} transition disablePortal>
                  {({ TransitionProps }) => (
                    <Grow {...TransitionProps} >
                      <Paper>
                        <ClickAwayListener onClickAway={() => setOpenSplitButton(false)}>
                          <MenuList id="split-button-menu" autoFocusItem>
                            {splitButtonDisplay.map((option, index) => (
                              <MenuItem
                                key={option}
                                selected={index === selectedIndex}
                                onClick={(event) => handleButtonSelected(event, index)}
                              >{option}</MenuItem>
                            ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </>
            )}
            {(!chargeTypeRequired || !isSuperParty) && (
              <CngPrimaryButton onClick={() => { handleSubmit() }}>{buttonLabel}</CngPrimaryButton>
            )}
          </>
        }
        dialogContent={translatedTextsObject.submitOneRecordAlert}
        dialogTitle={translatedTextsObject.submitRecord}
        open={openDialog}
        onClose={() => {
          setOpenDialog(false)
        }}
      />
    </CngGridItem>
  )
}
export default CustomButtonSection
