import React, { useEffect } from 'react'
import Namespace from 'src/constants/locale/Namespace'
import ArrivalmessageKeys from 'src/constants/locale/key/Arrivalmessage'
import { Grid, Card, CardContent } from '@material-ui/core'
import { components, DataFlattener, constants } from 'cng-web-lib'
import makeValidationSchema from './MakeValidationSchema'
import { useTranslation } from 'cng-web-lib'
import ArrivalMessageSNDTOsFormProperties from './ArrivalMessageSNDTOsFormProperties'
import { NaSublocationAutocompleteField } from 'src/components/na'
import { useLocation } from 'react-router-dom'
import moment from 'moment'
import { useFormContext,useWatch } from "react-hook-form";
import {FileForUserGetUserDetails} from 'src/common/FileForUserCommon'


const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngTextField,
      CngDateTimeField,
      CngSwitchField,
      CngCodeMasterAutocompleteField
    },
    CngAddFormButtonSection,
    CngEditFormButtonSection
  },
  table: {
    CngLocalModeDialogFormTable,
    useFetchCodeMaintenanceLookup
  },
  CngGridItem,
} = components

const {
  filter: { EQUAL }
} = constants

const DEFAULT_INITIAL_VALUES = Object.freeze({
 
  conveyancereferencenumber: "",
  arrivaldatetime: "",
 
  module: "",
  scheduledSubmissionFlag: false,
  scheduleDateAndTime: "",
  templateFlag: "",
  templateName: "",
  status: "",
  autoSubmitTimeZone: "",
  inbondDestincationOfficeCode: "",
  warehouseCode: "",
  messageFunction: "",
  csaArrival: "",
  businessNumber: "",
  arrivalMessageSNDTOs: [],
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  
  makeValidationSchema: makeValidationSchema
  
}

function Fields({ disabled, showNotification, shouldHideMap }) {
  const { setValue, getValues, reset } = useFormContext();
  const location = useLocation();
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const docType = location.pathname.toString().indexOf("air") === -1 ? (location.pathname.toString().indexOf("highway") === -1 ? "AIR" : "HIGHWAY") : "OCEAN"

  const flag = getValues("scheduledSubmissionFlag");
  const autoSubmitTimeZone = getValues("autoSubmitTimeZone");
  const scheduleDateAndTime = getValues("scheduleDateAndTime");
  const isTemplate = getValues("templateFlag");
  const templateName = getValues("templateName");
  const addPage = location.pathname.toString().indexOf("add") != -1 ? true : false
  const viewPage = location.pathname.toString().indexOf("view") != -1 ? true : false
  const editPage = location.pathname.toString().indexOf("edit") != -1 ? true : false

  const showCsaArrivalValue = getValues("csaArrival");

  const status = getValues("status");

  let isEditable = false;

  const templateStatus = status === "1002" ? true : false
  if (editPage && isTemplate && templateStatus) {
    isEditable = true;
  }


  useEffect(() => {
    if (flag === false && autoSubmitTimeZone != "") {
      setValue("autoSubmitTimeZone", "", { shouldValidate: true })
    }
    if (flag == false && scheduleDateAndTime != "") {
      setValue("scheduleDateAndTime", "", { shouldValidate: true })
    }
    if (isTemplate == false && templateName != "") {
      setValue("templateName", "", { shouldValidate: true })
    }
  }, [autoSubmitTimeZone]);

  const { translate } = useTranslation(Namespace.ARRIVALMESSAGE)
  const translatedTextsObject = makeTranslatedTextsObject()

  useWatch("fileForUserId");
  useWatch("fileForUserPartyId");
  useWatch("fileForUserLoginId");
  useEffect(() => { 
    let fileForUserDetails = FileForUserGetUserDetails();
      if(fileForUserDetails!=null && fileForUserDetails!=undefined){
        setValue("fileForUserId",fileForUserDetails.fileForUserId)
        setValue("fileForUserPartyId",fileForUserDetails.fileForUserPartyId)
        setValue("fileForUserLoginId",fileForUserDetails.fileForUserLoginId)
      }
   }, []);

  function makeTranslatedTextsObject() {
    let arrivalmessage = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.TITLE
    )
    let arrivaldatetime = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.ARRIVALDATETIME
    )
    let module = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.MODULE
    )
    let scheduledSubmissionFlag = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.SCHEDULED_SUBMISSION_FLAG
    )
    let scheduleDateAndTime = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.SCHEDULE_DATE_AND_TIME
    )
    let autoSubmitTimeZone = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.AUTO_SUBMIT_TIME_ZONE
    )

    let templateFlag = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.TEMPLATE_FLAG
    )
    let templateName = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.TEMPLATE_NAME
    )

    let creationdatetime = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.CREATIONDATETIME
    )
    let arrivalMessageSNDTOs = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.ArrivalMessageSNDTOs.TITLE
    )
    let email = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.ArrivalMessageSNDTOs.EMAIL
    )
    let phno = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.ArrivalMessageSNDTOs.PHNO
    )
    let snTyp = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.ArrivalMessageSNDTOs.SNTYP
    )

    let inbondDestincationOfficeCode = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.INBONDDESTINATIONOFFICECODE
    )
    let warehouseCode = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.WAREHOUSECODE
    )
    let messageFunction = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.MESSAGEFUNCTION
    )
    let csaArrival = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.CSAARRIVAL
    )
    let businessNumber = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.BUSINESSNUMBER
    )
    let ccn = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.CCN
    )
    return {
      arrivalmessage,
      arrivaldatetime,
      scheduledSubmissionFlag,
      scheduleDateAndTime,
      autoSubmitTimeZone,
      creationdatetime,
      templateFlag,
      templateName,
      arrivalMessageSNDTOs,
      email,
      phno,
      snTyp,
      module,
      inbondDestincationOfficeCode,
      warehouseCode,
      messageFunction,
      csaArrival,
      businessNumber,
      ccn
    }
  }

	return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card>
          <CngSimpleCardHeader title={translatedTextsObject.arrivalmessage} />
          <CardContent>
            <Grid container spacing={3}>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.conveyancereferencenumber}>
                <CngTextField
                  name="conveyancereferencenumber"
                  inputProps={{ maxLength: 25 }}
                  label={translatedTextsObject.ccn + " *"}
                  disabled={disabled}
                  onBlur={(e) => { setValue('conveyancereferencenumber', e.target.value.toUpperCase()) }}
                />
              </CngGridItem>

              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.arrivaldatetime}>
                <CngDateTimeField
                  name="arrivaldatetime"
                  format="YYYY-MM-DD HH:mm:ss"
                  label={translatedTextsObject.arrivaldatetime + " *"}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.scheduledSubmissionFlag}>
                <CngSwitchField
                  name="scheduledSubmissionFlag"
                  label={translatedTextsObject.scheduledSubmissionFlag}
                  disabled={disabled}
                />
              </CngGridItem>
              {(flag === true) && (
                <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.scheduleDateAndTime}>
                  <CngDateTimeField
                    name="scheduleDateAndTime"
                    format="YYYY-MM-DD HH:mm:ss"
                    shouldDisableDate={(scheduleDateAndTime) => {
                      return moment().isAfter(moment(scheduleDateAndTime))
                    }}
                    label={translatedTextsObject.scheduleDateAndTime + " *"}
                    disabled={disabled}
                  />
                </CngGridItem>
              )}
              {(flag === true) && (
                <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.autoSubmitTimeZone}>
                  <CngCodeMasterAutocompleteField
                    name='autoSubmitTimeZone'
                    label={translatedTextsObject.autoSubmitTimeZone + ' *'}
                    disabled={disabled}
                    codeType='SCHEDULER_TIME_ZONE'
                  />
                </CngGridItem>
              )}
            </Grid>

            <Grid container spacing={3}>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.inbondDestincationOfficeCode}>
                <NaSublocationAutocompleteField
                  name="inbondDestincationOfficeCode"
                  label={translatedTextsObject.inbondDestincationOfficeCode + " *"}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.warehouseCode}>
                <NaSublocationAutocompleteField
                  name='warehouseCode'
                  label={translatedTextsObject.warehouseCode + ' *'}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.csaArrival}>
                <CngCodeMasterAutocompleteField
                  name='csaArrival'
                  label={translatedTextsObject.csaArrival}
                  disabled={disabled}
                  codeType='HIGHWAY_CSA_ARRIVAL'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={showCsaArrivalValue !== "Y"}>
                <CngTextField
                  name="businessNumber"
                  inputProps={{ maxLength: 16 }}
                  label={translatedTextsObject.businessNumber + " *"}
                  disabled={disabled}
                  onBlur={(e) => { setValue('businessNumber', e.target.value.toUpperCase()) }}

                />
              </CngGridItem>
            </Grid>
            <Grid container spacing={3}>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.templateFlag}>
                <CngSwitchField
                  name="templateFlag"
                  label={translatedTextsObject.templateFlag}
                  disabled={disabled || isEditable}
                />
              </CngGridItem>
              {(isTemplate === true) && (
                <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.templateName}>
                  <CngTextField
                    name="templateName"
                    inputProps={{ maxLength: 50 }}
                    label={translatedTextsObject.templateName + " *"}
                    disabled={disabled || isEditable}              
                    onBlur={(e) => { setValue('templateName', e.target.value.toUpperCase()) }}

                  />
                </CngGridItem>
              )}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CngSimpleCardHeader title={translatedTextsObject.arrivalMessageSNDTOs} />
          <CardContent>
            <CngLocalModeDialogFormTable
              fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
              disabled={disabled}
              showNotification={showNotification}
              propertyName="arrivalMessageSNDTOs"
              tableColumns={[
                {
                  title: translatedTextsObject.email,
                  field: "email",
                },
                {
                  title: translatedTextsObject.snTyp,
                  field: "snTyp",
                  filterPlaceholder: ' ',
                  customLookup: () => {
                    return fetchCodeMaintenanceLookup(
                      constants.CodeMaintenanceType.CODE_MASTER,
                      undefined,
                      [{ field: 'codeType', operator: EQUAL, value: 'SYSTEM_STATUS' }],
                      undefined,
                      'code'
                    )
                  }

                }
              ]}
              addButtonProps={{
                disableIf: (tableData) => {
                  return tableData.length >= 5
                },
                customTooltip: (tableData) => tableData.length >= 5 ? 'Only 5 records allowed' : 'Add',
              }}
              formProperties={ArrivalMessageSNDTOsFormProperties}
              initialData={DEFAULT_INITIAL_VALUES.arrivalMessageSNDTOs}
              isViewOnly={disabled}
              idGenerated
              options={{
                pageSize: 5,
                pageSizeOptions: [5, 3],
              }
              }
              renderAddFormButtonSection={() => {
                return (
                  <CngAddFormButtonSection primaryButtonLabel="Save" shouldHideResetButton={true}>
                  </CngAddFormButtonSection>
                )
              }}
              renderEditFormButtonSection={() => {
                return (
                  <CngEditFormButtonSection primaryButtonLabel="Update" shouldHideResetButton={true}>
                  </CngEditFormButtonSection>
                )
              }}
            />
          </CardContent>
        </Card>
      </Grid>
      {(editPage || viewPage) && (
        <CngGridItem xs={12} shouldHide={shouldHideMap.messageFunction}>
          <Grid container justify='center'>
            <Grid item xs={6}>
              <CngCodeMasterAutocompleteField
                name='messageFunction'
                label={translatedTextsObject.messageFunction}
                disabled={disabled}
                codeType='MESSAGE_FUNCTION'
              />
            </Grid>
          </Grid>
        </CngGridItem>
      )}
      {(addPage) && (
        <CngGridItem xs={12} shouldHide={shouldHideMap.messageFunction}>
          <Grid container justify='center'>
            <Grid item xs={6}>
              <CngCodeMasterAutocompleteField
                name='messageFunction'
                label={translatedTextsObject.messageFunction}
                disabled={disabled}
                codeType='MESSAGE_FUNCTION_ADD'
              />
            </Grid>
          </Grid>
        </CngGridItem>
      )}
    </Grid>
	)
}

function toClientDataFormat(serverData) {
  let localData = DataFlattener.parse(serverData);


  return localData;
}

function toServerDataFormat(localData) {



  return DataFlattener.unflatten(localData);
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
