import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import {
  constants,
} from 'cng-web-lib'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )


  return Yup.object({
    email: Yup.string().matches(/^[a-zA-Z0-9][.a-zA-Z0-9-_\s]+@[a-zA-Z0-9-\s].+\.+[a-zA-Z]{2,5}$/, "Please enter valid email address").required(requiredMessage).nullable(),
    phno: Yup.string().matches(/^\d+$/, "Only numbers are allowed!").nullable(),
    snTyp: Yup.string().required(requiredMessage).nullable()
  })
}

export default makeValidationSchema
