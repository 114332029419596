import React from 'react'
import { components } from 'cng-web-lib'
import { Grid, Typography } from '@material-ui/core'
import { format } from 'date-fns'
import CngSection from 'src/components/cngcomponents/CngSection'

const { button: { CngButton } } = components

function ErrorMessageSection({ errorMessages }) {

    const max = 7
    let index = 0

    function getContent() {
        let content = []
        index = 0

        for (let message in errorMessages) {
            if (index == max) {
                index++
                content.push(
                    <Grid item xs={12}>
                        <Typography color='error'>............</Typography>
                    </Grid>
                )
                break
            }
            if (Array.isArray(errorMessages[message])) {
                for (let innerMessage in errorMessages[message]) {
                    if (index == max) { break }

                    content.push(
                        <Grid item xs={12}>
                            <Typography>{errorMessages[message][innerMessage]}</Typography>
                        </Grid>
                    )
                    index++
                }
            } else {
                content.push(
                    <Grid item xs={12}>
                        <Typography>{errorMessages[message]}</Typography>
                    </Grid>
                )
                index++
            }
        }

        return content
    }

    function downloadErrorMessages() {
        const link = document.createElement("a");
        const file = new Blob([getFormattedText()], { type: 'text/plain' });

        link.href = URL.createObjectURL(file);
        link.download = 'errorMessage_' + format(new Date(), 'yyyyMMddHHmmss') + '.txt';
        link.click();
        URL.revokeObjectURL(link.href);
    }

    function getFormattedText() {
        let text = null
        errorMessages.forEach((e) => {
            if (text) {
                text += `\r\n${e}`
            } else {
                text = e
            }
        })

        return text
    }

    return (
        <>
            {errorMessages.length > 0 && (
                <Grid item xs={12}>
                    <CngSection title={<Typography color='error'><b>Error Messages</b></Typography>}>
                        <Grid container spacing={1}>
                            {getContent()}
                            {index > max && (
                                <Grid container justify='flex-end'>
                                    <CngButton color='primary' size='medium' onClick={() => { downloadErrorMessages() }}>Download Error Messages</CngButton>
                                </Grid>
                            )}
                        </Grid>
                    </CngSection>
                </Grid>
            )}
        </>
    )
}

export default ErrorMessageSection