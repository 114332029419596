import { Grid } from '@material-ui/core'
import React from 'react'
import { components, useTranslation } from 'cng-web-lib'

import ArrivalViewForm from './ArrivalViewPage'
import ArrivalResponse from './ArrivalResponse'
import { useParams } from 'react-router-dom'
import Namespace from 'src/constants/locale/Namespace'
import AuditLog from './AuditLog.js'
import ArrivalKeys from 'src/constants/locale/key/Arrivalmessage';

const { CngTabs } = components


function ViewPage({ showNotification }) {
	const { id } = useParams()
  const { translate } = useTranslation([Namespace.ARRIVALMESSAGE])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let arrivalmessage = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalKeys.TITLE
    )
    let arrivalResponse= translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalKeys.ArrivalResponse.TITLE
    )

    return {
      arrivalmessage,
      arrivalResponse
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngTabs
          headerColor='primary'
          tabs={[
            {
              tabName: translatedTextsObject.arrivalmessage,
              tabContent: (
                <ArrivalViewForm
                  showNotification={showNotification}
                  id={id}
                />
              )
            },
            {
              tabName: translatedTextsObject.arrivalResponse,
              tabContent: (
                <ArrivalResponse
                  showNotification={showNotification}
                  arrivalId={id}
                  isViewOnly={true}
                />
              )
            },
            {
              tabName: "Activity Log",
              tabContent: (
                <AuditLog
                  showNotification={showNotification}
                  arrivalId={id}
                  isViewOnly={true}
                />
              )
            }
          ]}
        />
      </Grid>
    </Grid>
  );
}

export default ViewPage