import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import {
  constants,
} from 'cng-web-lib'
//import { format } from 'date-fns';
import moment from 'moment'



const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants


function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  // let dateTypeErrorMessage = translate(
  //   Namespace.COMMON_VALIDATION_MSG,
  //   CommonValidationMessageKeys.DATE_TYPE_ERROR
  // )
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  return Yup.object(
    
    {      
   
    conveyancereferencenumber: Yup.string().required(requiredMessage).matches(/^[a-zA-Z0-9-]+$/," Please enter alpha numeric or special charater (-) with out spaces").nullable(),
  // arrivaldatetime: Yup.date().min(today,`Arrival Date must be earlier than ${format(new Date(), 'yyyy-MM-dd')}`).nullable().required(requiredMessage),
   arrivaldatetime: Yup.string().required(requiredMessage).test(
    'arrivaldatetime',
    'Please select a valid Date Time in yyyy-mm-dd HH:mm:ss format.',
    value => {
      var formattedDate = moment(value).format('YYYY-MM-DD')
      var formattedToday = moment(today).format('YYYY-MM-DD')
           return moment(formattedDate).isValid();
                 }
               ).nullable(),
    scheduledSubmissionFlag: Yup.boolean().required(requiredMessage).nullable(),
    autoSubmitTimeZone: Yup.string().when('scheduledSubmissionFlag', {
      is: false,
      then: schema => schema,
      otherwise: Yup.string().when('scheduledSubmissionFlag', {
        is: true,
        then: Yup.string().required("Please select timezone for autosubmit")
      })
    }).nullable(),
    templateFlag: Yup.boolean(),
    templateName: Yup.string().when('templateFlag', {
      is: true,
      then: Yup.string().max(50, "Max length is 50").required(requiredMessage).matches(/^[a-zA-Z0-9\s]+$/, "Only alphanumeric characters are allowed."),
    }).nullable(),
    scheduleDateAndTime: Yup.string().when('scheduledSubmissionFlag',{
      is: false,
      then: schema => schema,
      otherwise: Yup.string().when('scheduledSubmissionFlag', {
        is: true,
        then: Yup.string().required(requiredMessage).when('scheduledSubmissionFlag',{
        is : true,
        then: Yup.string().test(
          'scheduleDateAndTime',
          'Please select a valid Future Scheduled Date Time in yyyy-mm-dd HH:mm:ss format.',
          value => {
            var formattedDate = moment(value).format('YYYY-MM-DD')
            var formattedToday = moment(today).format('YYYY-MM-DD')
            return moment(formattedDate).isAfter(formattedToday, 'days')
          }),
      })
    })
  }).nullable(),
  arrivalMessageSNDTOs: Yup.array(),

  
  inbondDestincationOfficeCode:Yup.string().required(requiredMessage).nullable(),
  warehouseCode:Yup.string().required(requiredMessage).nullable(),
  csaArrival: Yup.string().nullable(),
  businessNumber:Yup.string().when('csaArrival', {
    is: (csaArrival) => (csaArrival === 'Y'  ),
    then: Yup.string().required(requiredMessage),
  }).nullable(),

  messageFunction: Yup.string().when('status',{
    is: (value)=>value === "1001" || value === "1004",
    then: Yup.string().test('messageFunction','Rejected record should submit as Original.',function(value){
        if(value!=9)
        {
          return false;
        }
        else{
          return true;
        }
    }),
    otherwise: Yup.string().when('status',{
      is: (value)=>value === "1003",
      then: Yup.string().test('messageFunction','Cancelled record should submit as Original.',function(value){
        if(value!=9){
          return false;
        }
        else{
          return true;
        }
      }),
      otherwise: Yup.string().when('status',{
        is: (value)=>value === "1000",
        then: Yup.string().test('messageFunction','Accepted record cannot submit as Original.',function(value){
          if(value==9){
            return false;
          }
          else{
            return true;
          }
        })
      })
    })
  }).nullable() 
  
  })
   
}

export default makeValidationSchema
