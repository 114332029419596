import React from 'react'
import { components, constants } from 'cng-web-lib'
import { Box } from '@material-ui/core'
import FormProperties from './FormProperties'

const {
    button: { CngButton },
    form: { CngForm },
    CngDialog
} = components

const { FormState } = constants

function Dialog(props) {
    const { open, loading, billingConfig, onClose,
        onCreateRecord, onUpdateRecord, showNotification } = props

    const { initialValues, makeValidationSchema } = FormProperties.formikProps

    return (
        <CngDialog
            dialogContent={
                <CngForm
                    fieldLevel='form'
                    hookFormProps={{
                        initialValues: { ...(billingConfig || initialValues) },
                        makeValidationSchema: makeValidationSchema,
                        onSubmit: (data) => {
                            if (billingConfig) {
                                onUpdateRecord(data)
                            } else {
                                onCreateRecord(data)
                            }
                        }
                    }}
                    formState={FormState.COMPLETED}
                    renderBodySection={(labelMap, shouldHideMap) => (
                        <FormProperties.Fields
                            showNotification={showNotification}
                            shouldHideMap={shouldHideMap}
                        />
                    )}
                    innerForm={true}
                    renderButtonSection={() => (
                        <Box display='flex' justifyContent='flex-end'>
                            <CngButton color='primary' size='medium' type='submit'
                                disabled={loading} shouldShowProgress={loading}>
                                {billingConfig ? 'Update Record' : 'Save Record'}
                            </CngButton>
                        </Box>
                    )}
                />
            }
            dialogAction={null}
            dialogTitle='Billing Configuration'
            open={open}
            onClose={onClose}
            maxWidth='lg'
            fullWidth
        />
    )
}

export default Dialog
