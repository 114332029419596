import ArrivalmessageApiUrls from 'src/apiUrls/ArrivalmessageApiUrls'
import FormProperties from './FormProperties'
import { Grid } from '@material-ui/core'
import React, { useRef, useEffect, useState } from 'react'
import { components, constants, useServices } from 'cng-web-lib'
import { useParams, useLocation } from 'react-router-dom'
import CustomButtonSection from 'src/views/common/CustomButtonSection'
import pathMap from 'src/paths/pathMap'
import ChargeTypeDialog from 'src/views/common/ChargeTypeDialog'
import ErrorMessageDialog from 'src/views/common/ErrorMessageDialog'
import { useFormContext } from 'react-hook-form'
import { FileForUserGetUserDetails, GetLoggedInUserPartyName } from 'src/common/FileForUserCommon'
import ConfirmDialog from 'src/views/common/ConfirmDialog'
import moment from 'moment-timezone'

const {
  button: { CngButton },
  form: { CngEditForm, CngEditFormButtonSection },
  table: { useFetchCodeMaintenanceLookup }
} = components

const { filter: { EQUAL } } = constants

function EditPage(props) {
  const { history, onSetLoading, showNotification } = props

  const { id } = useParams()
  const loc = useLocation()
  const serverDataRef = useRef()
  let formikPropsData;


  formikPropsData = {
    ...FormProperties.formikProps,
    initialValues: {
      ...FormProperties.formikProps.initialValues,
      module: loc.pathname.toString().indexOf("air") !== -1 ? "AIR" : "OCEAN"
    }
  }

  const { securedSendRequest } = useServices()

  const [redirectPage, setRedirectPage] = useState({ url: "" });
  const [isSuperParty, setSuperParty] = useState(false);
  const [chargeTypes, setChargeTypes] = useState([])
  const [submitDialog, setSubmitDialog] = useState({ open: false, items: {} })
  const [arrivalDateValidateDialog, setArrivalDateValidateDialog] = useState({ open: false, items: {} })
  const [errorMessageDialog, setErrorMessageDialog] = useState({ open: false, errorMessages: [] })

  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const [autoSendFlag, setAutoSendFlag] = useState(false);

  useEffect(() => {

    if (loc.pathname.toString().indexOf("ocean") != -1) {
      console.log("ocean")
      setRedirectPage({ url: pathMap.ACI_OCN_ARRIVAL_MESSAGE_LIST_VIEW })
    } else if (loc.pathname.toString().indexOf("shared") != -1) {
      setRedirectPage({ url: pathMap.ARRIVALMESSAGE_LIST_VIEW });
    } else if (loc.pathname.toString().indexOf("air") != -1) {
      setRedirectPage({ url: pathMap.ACI_AIR_ARRIVAL_MESSAGE_LIST_VIEW });
    }

    Promise.all([
      // Charge type
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'BILLING_CHARGE_TYPE' }],
        undefined,
        'code'
      ),
      // Super party
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'BILLING_SUPER_PARTY' }],
        undefined, 'code'
      )
    ]).then(
      ([billingChargeTypes, billingSuperParty]) => {
        compileChargeTypes(billingChargeTypes)

        Object.keys(billingSuperParty).forEach(e => {
          if (e == GetLoggedInUserPartyName()) {
            setSuperParty(true);
          }
        })
      }
    )
  }, [])

  function compileChargeTypes(billingChargeTypes) {
    var jsonArray = [];
    if (billingChargeTypes) {
      Object.entries(billingChargeTypes).forEach((e) => {
        var jsonObject = { code: e[0], desc: e[1] }
        jsonArray.push(jsonObject)
      })
    }
    setChargeTypes(jsonArray)
  }

  function preSubmitAM(data) {
    if (isSuperParty) {
      setSubmitDialog({ open: true, items: data })
    } else {
      data.chargeAs = 'REGULAR'
      handleSubmitAM(data)
    }
  }

  function handleSubmitAM(data) {
    onSetLoading(true)

    const fileForUserDetails = FileForUserGetUserDetails()
    if (fileForUserDetails) {
      const { fileForUserId, fileForUserLoginId, fileForUserPartyId } = fileForUserDetails
      data.fileForUserId = fileForUserId
      data.fileForUserLoginId = fileForUserLoginId
      data.fileForUserPartyId = fileForUserPartyId
    }

	data = FormProperties.toServerDataFormat(data)
    securedSendRequest.execute('POST', ArrivalmessageApiUrls.SUBMIT, data,
      (response) => {
        let data = response.data
        let errorMessages = []
        if (data.errorMessages != null && data.errorMessages.length > 0) {
          errorMessages.push(data.errorMessages);
          setErrorMessageDialog({ open: true, errorMessages })
        } else {
          showNotification('success', 'Arrival Message submitted successfully.')
          history.push(redirectPage.url)
        }
      },
      (error) => {
        console.error(error)
        showNotification('error', 'Arrival Message submit failed.')
      },
      () => onSetLoading(false)
    )
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngEditForm
          fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
          showNotification={showNotification}
          bodySection={
            <FormProperties.Fields
              disabled={false}
              showNotification={showNotification}
              setAutoSendFlag={setAutoSendFlag}
            />
          }
          formikProps={formikPropsData}
          toClientDataFormat={FormProperties.toClientDataFormat}
          toServerDataFormat={FormProperties.toServerDataFormat}
          fetch={{
            url: ArrivalmessageApiUrls.GET,
            onPreSuccess: (serverData) => {
              serverDataRef.current = serverData
            }
          }}
          update={{
            url: ArrivalmessageApiUrls.PUT
          }}
          id={id}
          renderButtonSection={(_, loading) => (
            <AMEditPageButtonSection
              history={history}
              loading={loading}
              onSetLoading={onSetLoading}
              showNotification={showNotification}
              onSubmitAM={preSubmitAM}
              redirectPage={redirectPage.url}
              autoSendFlag={autoSendFlag}
            />
          )}
        />
      </Grid>
      <ChargeTypeDialog
        chargeTypes={chargeTypes}
        onClose={() => setSubmitDialog({ open: false, items: {} })}
        onSubmit={handleSubmitAM}
        open={submitDialog.open}
        items={submitDialog.items}
      />
      <ErrorMessageDialog
        errorMessages={errorMessageDialog.errorMessages}
        open={errorMessageDialog.open}
        onClose={() => setErrorMessageDialog({ open: false })} />
    </Grid>
  )
}

export default EditPage

function AMEditPageButtonSection(props) {
  const { history, loading, onSetLoading, showNotification, serverDataRef, onSubmitAM, redirectPage, autoSendFlag } = props

  const [confirmDialog, setConfirmDialog] = useState(false)
  const { getValues, trigger, watch } = useFormContext()
  const [isWithinTwoHours, setIsWithinTwoHours] = useState(false);

  useEffect(() => {
    onSetLoading(loading)
  }, [loading])

  const combineDateAndTime = (date, time) => {
    if (date && time) {
      const dateTime = moment(date).set({
        hour: parseInt(time.substring(0, 2), 10),
        minute: parseInt(time.substring(2, 4), 10),
      });
      return moment(dateTime).tz("Canada/Eastern");
    }
    return "";
  };

  const arrivaldatetime = watch('arrivaldatetime');
  const arrivalDate = watch('arrivalDate');
  const arrivalTime = watch('arrivalTime')
  const now = moment().tz("Canada/Eastern");
  useEffect(() => {
    console.log("now datetime: " + now);
    const differenceInHours = arrivalDate && arrivalTime && Math.abs(now.diff(combineDateAndTime(arrivalDate, arrivalTime), 'hours'));
    console.log("differenceInHours: " + differenceInHours);
    console.log("differenceInHours <= 2: " + differenceInHours <= 2);
    if (differenceInHours <= 2) {
      setIsWithinTwoHours(true);
    } else {
      setIsWithinTwoHours(false);
    }
    console.log("isWithinTwoHours: " + isWithinTwoHours);
  }, [arrivalDate, arrivalTime])

  return (
    <>
      <Grid container justify='space-between' spacing={3}>
        <Grid item xs='auto'>
          <CngButton color='secondary' disabled={loading} onClick={() => setConfirmDialog(true)} size='medium'>
            Discard
          </CngButton>
        </Grid>
        <Grid item xs='auto'>
          <Grid container spacing={2}>
            {!autoSendFlag &&
              <>
                <Grid item xs='auto'>
                  <CngButton color='primary' disabled={loading} type='submit' size='medium' shouldShowProgress={loading}>
                    Update
                  </CngButton>
                </Grid>
                <Grid item xs='auto'>
                  <CngButton color='primary' disabled={loading} size='medium' onClick={async () => {
                    let result = await trigger()
                    if (result) {
                      if (isWithinTwoHours) {
                        onSubmitAM(getValues())
                      } else {
                        if (window.confirm('You are submitting arrival messsage in advance of your declared actual arrival date and time. Do you wish to continue?')) {
                          onSubmitAM(getValues())
                        }
                      }
                    }
                  }
                  }>Submit</CngButton>
                </Grid>
              </>
            }
            {autoSendFlag &&
              <Grid item xs='auto'>
                <CngButton color='primary' disabled={loading} type='submit' size='medium' shouldShowProgress={loading}>
                  Update & Submit
                </CngButton>
              </Grid>
            }
          </Grid>
        </Grid>
      </Grid>

      <ConfirmDialog
        isConfirmDialogOpen={confirmDialog}
        closeDialog={() => setConfirmDialog(false)}
        confirmDialog={() => history.push(redirectPage)}
        content="All progress in this session will be lost and cant't be restored. Are you sure about this?"
        okMsg='Yes, discard'
        cancelMsg='Continue editing'
        title='Discard'
      />
    </>
  )
}
