import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import {
  constants,
} from 'cng-web-lib'
//import { format } from 'date-fns';
import moment from 'moment'



const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  // let dateTypeErrorMessage = translate(
  //   Namespace.COMMON_VALIDATION_MSG,
  //   CommonValidationMessageKeys.DATE_TYPE_ERROR
  // )
  const today = new Date();
  today.setHours(0, 0, 0, 0);


  return Yup.object({
    carriercode: Yup.string().required(requiredMessage).min(4,"Min length is 4").matches(/^[a-zA-Z0-9-]+$/,"Cannot contain special characters like ),(,@ etc.").nullable(),
    conveyancereferencenumber: Yup.string().required(requiredMessage).matches(/^[a-zA-Z0-9-]+$/," Please enter alpha numeric or special charater (-) with out spaces").nullable(),
  // arrivaldatetime: Yup.date().min(today,`Arrival Date must be earlier than ${format(new Date(), 'yyyy-MM-dd')}`).nullable().required(requiredMessage),
    arrivalDate: Yup.string().required(requiredMessage).test(
    'arrivalDate',
    'Please select a valid Date in yyyy-mm-dd format.',
      value => {
        return  moment(value, 'YYYY-MM-DD').isValid()
      }
    ).nullable(),
    arrivalTime: Yup.string().required(requiredMessage).test(
      'arrivalTime',
      'Please select a valid Time in HH:mm format.',
      value => {
        return  moment(value, 'HH:mm').isValid()
      }
    ).nullable(),
    portofarrival: Yup.string().nullable().required(requiredMessage).nullable(),
    scheduledSubmissionFlag: Yup.boolean().required(requiredMessage).nullable(),
    autoSubmitTimeZone: Yup.string().when('scheduledSubmissionFlag', {
      is: false,
      then: schema => schema,
      otherwise: Yup.string().when('scheduledSubmissionFlag', {
        is: true,
        then: Yup.string().required("Please select timezone for autosubmit")
      })
    }).nullable(),
    templateFlag: Yup.boolean(),
    templateName: Yup.string().when('templateFlag', {
      is: true,
      then: Yup.string().max(50, "Max length is 50").required(requiredMessage).matches(/^[a-zA-Z0-9\s]+$/, "Only alphanumeric characters are allowed."),
    }).nullable(),
    /*scheduleDateAndTime: Yup.string().when('scheduledSubmissionFlag',{
      is: false,
      then: schema => schema,
      otherwise: Yup.string().when('scheduledSubmissionFlag', {
        is: true,
        then: Yup.string().required(requiredMessage).when('scheduledSubmissionFlag',{
        is : true,
        then: Yup.string().test(
          'scheduleDateAndTime',
          'Please select a valid Future Scheduled Date Time in yyyy-mm-dd HH:mm:ss format.',
          value => {
            var formattedDate = moment(value).format('YYYY-MM-DD')
            var formattedToday = moment().subtract(1,'days').format('YYYY-MM-DD')
            return moment(formattedDate).isAfter(formattedToday, 'days')
          }),
      })
    })*/
  scheduleDate: Yup.string().when('scheduledSubmissionFlag',{
      is: false,
      then: schema => schema,
      otherwise: Yup.string().when('scheduledSubmissionFlag', {
        is: true,
        then: Yup.string().required(requiredMessage).when('scheduledSubmissionFlag',{
        is : true,
        then: Yup.string().test(
          'scheduleDate',
          'Please select a valid Future Scheduled Date Time in yyyy-mm-dd format.',
          value => {
            return moment(value, 'YYYY-MM-DD').isValid()
          }),
      })
    })
    }).nullable(),
  scheduleTime: Yup.string().when('scheduledSubmissionFlag',{
    is: false,
    then: schema => schema,
    otherwise: Yup.string().when('scheduledSubmissionFlag', {
      is: true,
      then: Yup.string().required(requiredMessage).when('scheduledSubmissionFlag',{
      is : true,
      then: Yup.string().test(
        'scheduleTime',
        'Please select a valid Future Scheduled Time in HH:mm format.',
        value => {
          return moment(value, 'HH:mm').isValid()
        }),
    })
  })
  }).nullable(),
  
  arrivalMessageSNDTOs: Yup.array(),
  })
}

export default makeValidationSchema
