//import { CrudTable, DateRangeFilter } from "tfp/components";
import { Card, CardContent, Grid } from '@material-ui/core'
import moment from 'moment'
//import CrudCustomMaterialTable from "tfp/components/tables/materialtable/ResponseCrudCustomMaterialTable.js";
import AuditApiUrl from "src/apiUrls/AuditApiUrl.js";
import React from "react";
import {
  components,
  constants,
  useTranslation
} from 'cng-web-lib'
import SharedKeys from 'src/constants/locale/key/Arrivalmessage';
import Namespace from 'src/constants/locale/Namespace';



const {
  card: {  CngSimpleCardHeader },
  table: {
     CngCrudTable,
     DateRangeFilter,
  }
} = components

const {
  locale: {
    key: { UiComponentKeys }
  }
} = constants

function AuditLog({
  showNotification,
  
  arrivalId,
  isViewOnly = false
}){

  const { translate } = useTranslation([Namespace.ARRIVALMESSAGE])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let shared = translate(
      Namespace.ARRIVALMESSAGE,
      SharedKeys.TITLE
    )
  
    let carriercode = translate(
      Namespace.ARRIVALMESSAGE,
      SharedKeys.CARRIERCODE
    )
    let conveyancereferencenumber = translate(
      Namespace.ARRIVALMESSAGE,
      SharedKeys.CONVEYANCEREFERENCENUMBER
    )
    let arrivaldatetime = translate(
      Namespace.ARRIVALMESSAGE,
      SharedKeys.ARRIVALDATETIME
    )
    let portofarrival = translate(
      Namespace.ARRIVALMESSAGE,
      SharedKeys.PORTOFARRIVAL
    )
    let creationdatetime = translate(
      Namespace.ARRIVALMESSAGE,
      SharedKeys.CREATIONDATETIME
    )
    let scheduledSubmissionFlag = translate(
      Namespace.ARRIVALMESSAGE,
      SharedKeys.AUTOSEND
    )
    let status = translate(
      Namespace.ARRIVALMESSAGE,
      SharedKeys.STATUS
    )

    return {
      shared,
      carriercode,
      conveyancereferencenumber,
      arrivaldatetime,
      portofarrival,
      creationdatetime,
      scheduledSubmissionFlag,
      status
    }
  }

  const columns = [
    {
      field: "createdDate",
      title: "Created Date and Time",
      type: "date",
      filterComponent: DateRangeFilter,
      render: (rowData) => {
        return moment(rowData.createdDate).format('DD/MM/YYYY HH:mm:ss')
      }
    },
    {
      field: "userId",
      title: "Login User ID",
    },
    {
      field: "functionCode",
      title: "Action",
    },
    {
        field: "auditDescription",
        title: "Remarks",
    }
  ]; 

  return (
  <Card>
      <CngSimpleCardHeader title="Activity Log" />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CngCrudTable
              //{...props}
              fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
              columns={columns}
              fetch={{ url: AuditApiUrl.GET}}
              idAccessor={arrivalId}
              notification={showNotification}
              fetchFilters={[{ field: 'keyReferenceNo', operator: 'equal', value: 'ACI_OCN_AM_' + arrivalId }]}
              options={{
                pageSize: 10,
                pageSizeOptions: [10, 20, 50, 100, 500, 1000]
              }}
            />
          </Grid>
        </Grid>
    </CardContent>
    </Card>
  )
}
export default AuditLog;
