import { Yup, constants } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'

const {
    locale: { key: { CommonValidationMessageKeys } }
} = constants

function ValidationSchema(translate) {
    let requiredMessage = translate(
        Namespace.COMMON_VALIDATION_MSG,
        CommonValidationMessageKeys.REQUIRED
    )

    return Yup.object({
        chargeCode: Yup.string().required(requiredMessage).max(40, "Maximum length of 40 allowed."),
        msgFunction: Yup.string().nullable().max(5, "Maximum length of 5 allowed."),
        moduleName: Yup.string().required(requiredMessage),
        submissionType: Yup.string().nullable().max(25, "Maximum length of 25 allowed."),
        docType: Yup.string().required(requiredMessage).max(50, "Maximum length of 50 allowed."),
        status: Yup.string().nullable(),
        partyIdList: Yup.string().nullable()
    })
}

export default ValidationSchema