import ArrivalmessageApiUrls from 'src/apiUrls/ArrivalmessageApiUrls'
import FormProperties from './FormProperties'
import { Grid } from '@material-ui/core'
import React from 'react'
import { components } from 'cng-web-lib'
import pathMap from 'src/paths/pathMap'
import { useLocation } from 'react-router-dom'
import CustomButtonSection from 'src/views/common/CustomButtonSection'

const {
  form: {  CngAddForm ,CngAddFormButtonSection}
} = components

function AddPage({ history, showNotification }) {

    const loc = useLocation()
    let formikPropsData;
     let redirectPage;
     let moduleValue = loc.pathname.toString().indexOf("air") === -1 ? (loc.pathname.toString().indexOf("highway") === -1 ?"OCEAN" : "HIGHWAY"): "AIR";
    if(loc.state !== undefined) {
    formikPropsData = {
    ...FormProperties.formikProps,
    initialValues: {
      ...loc.state,
      module: loc.pathname.toString().indexOf("air") === -1 ? (loc.pathname.toString().indexOf("highway") === -1 ?"OCEAN" : "HIGHWAY"): "AIR"
      }
    }
   } else {
    formikPropsData = {
      ...FormProperties.formikProps,
      initialValues: {
        ...FormProperties.formikProps.initialValues,
        module: loc.pathname.toString().indexOf("air") === -1 ? (loc.pathname.toString().indexOf("highway") === -1 ?"OCEAN" : "HIGHWAY"): "AIR"
        }
      }
   }
   if(moduleValue === "OCEAN"){
      redirectPage = pathMap.ARRIVALMESSAGE_LIST_VIEW;
   }
   else if (moduleValue === "AIR"){ 
      redirectPage = pathMap.AIR_ARRIVAL_LIST_VIEW;
    }
    else if (moduleValue === "HIGHWAY"){ 
      redirectPage = pathMap.ACI_HIGHWAY_ARRIVAL_LIST_VIEW;
    }
    console.log("46 ......... " + moduleValue)
    console.log("47 ......... " + redirectPage)
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngAddForm
          fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
          history={history}
          showNotification={showNotification}
          formikProps={formikPropsData}
          bodySection={
            <FormProperties.Fields
              disabled={false}
              showNotification={showNotification}
            />
          }
         // formikProps={FormProperties.formikProps}
          toClientDataFormat={FormProperties.toClientDataFormat}
          toServerDataFormat={FormProperties.toServerDataFormat}
          create={{
            url: ArrivalmessageApiUrls.POST,
            successRedirect: redirectPage
          }}
          renderButtonSection={() => {
            return (
              <CngAddFormButtonSection primaryButtonLabel="Save">
                  <CustomButtonSection buttonLabel="Submit" url={ArrivalmessageApiUrls.SUBMIT} isAdd={true} showNotification={showNotification} redirect={redirectPage}>
                  </CustomButtonSection>
              </CngAddFormButtonSection>
            )
          }}
        />
      </Grid>
    </Grid>
  )
}

export default AddPage
