import React from 'react'
import { components } from 'cng-web-lib'
import { Grid, Typography } from '@material-ui/core'
import { format } from 'date-fns'

const { button: { CngButton }, CngDialog } = components

function ErrorMessageDialog({ errorMessages, open, onClose }) {

    const max = 20
    let index = 0;

    function getContent() {
        let content = []
        index = 0

        for (let message in errorMessages) {
            if (index == max) {
                index++
                content.push(
                    <Grid item xs={12}>
                        <Typography color='error'>............</Typography>
                    </Grid>
                )
                break
            }
            if (Array.isArray(errorMessages[message])) {
                for (let innerMessage in errorMessages[message]) {
                    if (index == max) { break }

                    content.push(
                        <Grid item xs={12}>
                            <Typography>{errorMessages[message][innerMessage]}</Typography>
                        </Grid>
                    )
                    index++
                }
            } else {
                content.push(
                    <Grid item xs={12}>
                        <Typography>{errorMessages[message]}</Typography>
                    </Grid>
                )
                index++
            }
        }

        return content
    }

    function downloadErrorMessages() {
        const link = document.createElement("a");
        const file = new Blob([getFormattedText()], { type: 'text/plain' });

        link.href = URL.createObjectURL(file);
        link.download = 'errorMessage_' + format(new Date(), 'yyyyMMddHHmmss') + '.txt';
        link.click();
        URL.revokeObjectURL(link.href);
    }

    function getFormattedText() {
        let text = null
        errorMessages.forEach((e) => {
            if (text) {
                text += `\r\n${e}`
            } else {
                text = e
            }
        })

        return text
    }

    return (
        <CngDialog
            dialogContent={<Grid container spacing={1}>{getContent()}</Grid>}
            dialogAction={
                <>
                    <CngButton color='secondary' onClick={onClose} size='medium'>Close</CngButton>
                    {index > max && (
                        <CngButton color='primary' size='medium' onClick={() => { downloadErrorMessages() }}>Download Error Messages</CngButton>
                    )}
                </>
            }
            dialogTitle={<Typography color='error'><b>Error Messages</b></Typography>}
            fullWidth
            maxWidth='md'
            onClose={onClose}
            open={open}
            shouldShowCloseButton
        />
    )
}

export default ErrorMessageDialog