import ArrivalmessageApiUrls from 'src/apiUrls/ArrivalmessageApiUrls'
import FormProperties from './FormProperties'
import { Grid } from '@material-ui/core'
import React,{ useRef } from 'react'
import { components } from 'cng-web-lib'
import { useParams,useLocation } from 'react-router-dom'
import CustomButtonSection from 'src/views/common/CustomButtonSection'
import pathMap from 'src/paths/pathMap'
const {
  form: {  CngEditForm ,CngEditFormButtonSection}
} = components 

function EditPage({ showNotification }) {
  const { id } = useParams()
  const loc = useLocation()
  const serverDataRef = useRef()
  let formikPropsData;
  let redirectPage;
  let moduleValue = loc.pathname.toString().indexOf("air") === -1 ? (loc.pathname.toString().indexOf("highway") === -1 ?"OCEAN" : "HIGHWAY"): "AIR";
  formikPropsData = {
    ...FormProperties.formikProps,
    initialValues: {
      ...FormProperties.formikProps.initialValues,
      module: loc.pathname.toString().indexOf("air") === -1 ? (loc.pathname.toString().indexOf("highway") === -1 ?"OCEAN" : "HIGHWAY"): "AIR"
      }
    }
  if(moduleValue === "OCEAN"){
      redirectPage = pathMap.ARRIVALMESSAGE_LIST_VIEW;
   }
  else if (moduleValue === "AIR"){ 
      redirectPage = pathMap.AIR_ARRIVAL_LIST_VIEW;
    } 
  else if (moduleValue === "HIGHWAY"){ 
      redirectPage = pathMap.ACI_HIGHWAY_ARRIVAL_LIST_VIEW;
    }
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngEditForm
          fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
          showNotification={showNotification}
          bodySection={
            <FormProperties.Fields
              disabled={false}
              showNotification={showNotification}
            />
          }
          formikProps={formikPropsData}
          toClientDataFormat={FormProperties.toClientDataFormat}
          toServerDataFormat={FormProperties.toServerDataFormat}
          fetch={{
            url: ArrivalmessageApiUrls.GET,
            onPreSuccess: (serverData) => {
              serverDataRef.current = serverData
            }
          }}
          update={{
            url: ArrivalmessageApiUrls.PUT
          }}
          id={id}
          renderButtonSection={() => {
            return (
              <CngEditFormButtonSection>
                <CustomButtonSection buttonLabel="Submit" url={ArrivalmessageApiUrls.SUBMIT} data={serverDataRef} isAdd={false} showNotification={showNotification} redirect={redirectPage}>
                </CustomButtonSection>
              </CngEditFormButtonSection>
            )
          }}
        />
      </Grid>
    </Grid>
  )
}

export default EditPage
