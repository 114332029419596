import React, { useEffect, useRef, useState } from 'react'
import { components, constants, useTranslation, useServices } from 'cng-web-lib'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Typography } from '@material-ui/core'
import ConfirmDialog from '../common/ConfirmDialog'
import Table from '../../components/aciacehighway/Table'
import TinyChip from 'src/components/aciacehighway/TinyChip'
import Namespace from 'src/constants/locale/Namespace'
import BillingConfigApiUrls from 'src/apiUrls/BillingConfigApiUrls'
import BillingConfigKeys from 'src/constants/locale/key/BillingConfig'
import Dialog from './Dialog'
import FormProperties from './FormProperties'

const {
    table: { useFetchCodeMaintenanceLookup }
} = components

const {
    locale: { key: { UiComponentKeys } },
    filter: { EQUAL, LIKE, IN }
} = constants

function TablePage(props) {
    const { showNotification, loading } = props
    const { createRecord, updateRecord, deleteRecord } = useServices()
    const tableRef = useRef(null)
    const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()

    const [billingConfigDialog, setBillingConfigDialog] = useState({
        open: false,
        billingConfig: null
    })

    const [confirmDialog, setConfirmDialog] = useState({
        open: false,
        billingConfig: null
    })

    const [lookups, setLookups] = useState(null)

    useEffect(() => {
        Promise.all([
            //Module
            fetchCodeMaintenanceLookup(
                constants.CodeMaintenanceType.CODE_MASTER,
                undefined,
                [{ field: 'codeType', operator: EQUAL, value: 'BILLING_MODULE' }],
                undefined, 'code'
            ),
            //Status
            fetchCodeMaintenanceLookup(
                constants.CodeMaintenanceType.CODE_MASTER,
                undefined,
                [{ field: 'codeType', operator: EQUAL, value: 'BILLING_STATUS' }],
                undefined, 'code'
            )
        ]).then(([module, status]) => {
            setLookups({ module, status })
        })
    }, [])

    function getLookupValue(name, value) {
        if (!lookups) return value

        return lookups[name] && lookups[name][value] ? lookups[name][value] : value
    }

    const { translate } = useTranslation([Namespace.UI_COMPONENT, Namespace.BILLING_CONFIG])
    const translatedTextsObject = makeTranslatedTextsObject()

    function makeTranslatedTextsObject() {
        let title = translate(
            Namespace.UI_COMPONENT,
            UiComponentKeys.Table.TITLE,
            {
                nameTitleised: "Billing Configuration"
            }
        )
        let chargeCode = translate(
            Namespace.BILLING_CONFIG,
            BillingConfigKeys.CHARGE_CODE
        )
        let msgFunction = translate(
            Namespace.BILLING_CONFIG,
            BillingConfigKeys.MSG_FUNCTION
        )
        let moduleName = translate(
            Namespace.BILLING_CONFIG,
            BillingConfigKeys.MODULE_NAME
        )
        let submissionType = translate(
            Namespace.BILLING_CONFIG,
            BillingConfigKeys.SUBMISSION_TYPE
        )
        let docType = translate(
            Namespace.BILLING_CONFIG,
            BillingConfigKeys.DOC_TYPE
        )
        let status = translate(
            Namespace.BILLING_CONFIG,
            BillingConfigKeys.STATUS
        )

        return {
            title,
            chargeCode,
            msgFunction,
            moduleName,
            submissionType,
            docType,
            status
        }
    }

    const columns = [
        {
            field: 'chargeCode',
            sortKey: 'chargeCode',
            title: `${translatedTextsObject.chargeCode}`
        }, {
            field: 'msgFunction',
            sortKey: 'msgFunction',
            title: `${translatedTextsObject.msgFunction}`
        }, {
            field: 'moduleName',
            sortKey: 'moduleName',
            title: `${translatedTextsObject.moduleName}`,
            render: (data) => {
                return (
                    <Typography component='div' variant='inherit'>
                        {getLookupValue('module', data.moduleName)}
                        <TinyChip label={data.moduleName} />
                    </Typography>
                )
            }
        }, {
            field: 'submissionType',
            sortKey: 'submissionType',
            title: `${translatedTextsObject.submissionType}`
        }, {
            field: 'docType',
            sortKey: 'docType',
            title: `${translatedTextsObject.docType}`
        }, {
            field: 'status',
            sortKey: 'status',
            title: `${translatedTextsObject.status}`,
            render: (data) => {
                return (
                    <Typography component='div' variant='inherit'>
                        {getLookupValue('status', data.status)}
                    </Typography>
                )
            }
        },
    ]

    const filters = [
        {
            label: translatedTextsObject.chargeCode,
            type: 'textfield',
            name: 'chargeCode',
            operatorType: LIKE
        },
        {
            label: translatedTextsObject.msgFunction,
            type: 'textfield',
            name: 'msgFunction',
            operatorType: LIKE
        },
        {
            label: translatedTextsObject.moduleName,
            type: 'textfield',
            name: 'moduleName',
            operatorType: LIKE
        },
        {
            label: translatedTextsObject.submissionType,
            type: 'textfield',
            name: 'submissionType',
            operatorType: LIKE
        },
        {
            label: translatedTextsObject.docType,
            type: 'textfield',
            name: 'docType',
            operatorType: LIKE
        },
        {
            label: translatedTextsObject.status,
            type: 'checkbox',
            name: 'status',
            operatorType: IN,
            options: [
                {
                    label: 'Draft',
                    value: 'DRAFT',
                    filterValue: { value: '1005' }
                },
                {
                    label: 'Accepted',
                    value: 'ACCEPTED',
                    filterValue: { value: '1000' }
                },
                {
                    label: 'Rejected',
                    value: 'REJECTED',
                    filterValue: { value: '1001' }
                },
                {
                    label: 'Status Notification',
                    value: 'STATUS_NOTIFICATION',
                    filterValue: { value: '1006' }
                },
                {
                    label: 'Cancel Accepted',
                    value: 'CANCEL_ACCEPTED',
                    filterValue: { value: '1003' }
                }
            ]
        },
    ]

    function handleCreateRecord(data) {
        if (data.status == null || data.status.length == 0) {
            data.status = null
        }

        if (data.msgFunction == null || data.msgFunction.length == 0) {
            data.msgFunction = null
        }

        if (data.submissionType == null || data.submissionType.length == 0) {
            data.submissionType = null
        }

        if (data.partyIdList == null || data.partyIdList.length == 0) {
            data.partyIdList = null
        }

        createRecord.execute(BillingConfigApiUrls.POST, FormProperties.toServerDataFormat(data),
            () => {
                tableRef.current.performRefresh()
                setBillingConfigDialog({ open: false, billingConfig: null })
                showNotification('success', 'Record created')
            },
            () => {
                showNotification('error', 'Creation failed')
            },
        )
    }

    function handleUpdateRecord(data) {
        if (data.status == null || data.status.length == 0) {
            data.status = null
        }

        if (data.msgFunction == null || data.msgFunction.length == 0) {
            data.msgFunction = null
        }

        if (data.submissionType == null || data.submissionType.length == 0) {
            data.submissionType = null
        }

        if (data.partyIdList == null || data.partyIdList.length == 0) {
            data.partyIdList = null
        }

        updateRecord.execute(BillingConfigApiUrls.PUT, FormProperties.toServerDataFormat(data),
            () => {
                tableRef.current.performRefresh()

                setBillingConfigDialog({ open: false, billingConfig: null })
                showNotification('success', 'Record updated')
            },
            () => {
                showNotification('error', 'Update failed')
            },
        )
    }

    function handleDeleteRecord() {
        if (confirmDialog.billingConfig) {
            deleteRecord.execute(
                BillingConfigApiUrls.DELETE,
                confirmDialog.billingConfig,
                () => {
                    showNotification('success', 'Record deleted')
                    setConfirmDialog({ open: false, billingConfig: null })
                    tableRef.current.performRefresh()
                },
                (error) => {
                    console.log(error)
                }
            )
        }
    }

    return (
        <>
            <Table
                actions={[
                    {
                        buttonProps: {
                            color: 'primary',
                            size: 'medium',
                            startIcon: <FontAwesomeIcon icon={['fal', 'plus-circle']} />,
                            onClick: () => setBillingConfigDialog({ open: true, billingConfig: null })
                        },
                        label: 'Create record'
                    }
                ]}
                columns={columns}
                compact
                fetch={{ url: BillingConfigApiUrls.GET }}
                filters={filters}
                onRowClick={(rowData) => setBillingConfigDialog({ open: true, billingConfig: FormProperties.toClientDataFormat(rowData) })}
                rowActions={[
                    {
                        icon: <FontAwesomeIcon icon={['fal', 'pen']} />,
                        label: 'Edit Record',
                        onClick: (rowData) => setBillingConfigDialog({ open: true, billingConfig: FormProperties.toClientDataFormat(rowData) })
                    },
                    {
                        icon: <FontAwesomeIcon icon={['fal', 'trash']} />,
                        label: 'Delete Record',
                        onClick: (rowData) => setConfirmDialog({ open: true, billingConfig: rowData }),
                    }
                ]}
                showNotification={showNotification}
                sortConfig={{
                    type: 'column',
                    defaultDirection: 'ASC',
                    defaultField: 'moduleName'
                }}
                persistSettings
                tableRef={tableRef}
            />
            <Dialog
                loading={loading}
                onClose={() => setBillingConfigDialog({ open: false, cargo: null })}
                onCreateRecord={handleCreateRecord}
                onUpdateRecord={handleUpdateRecord}
                open={billingConfigDialog.open}
                billingConfig={billingConfigDialog.billingConfig}
                showNotification={showNotification}
            />
            <ConfirmDialog
                isConfirmDialogOpen={confirmDialog.open}
                closeDialog={() => setConfirmDialog({ open: false, billingConfig: null })}
                confirmDialog={handleDeleteRecord}
                content="Items that you delete can't be restored. Are you sure about this?"
                okMsg='Yes, delete'
                cancelMsg='No, take me back'
                title='Delete record'
            />
        </>
    )
}

export default TablePage
