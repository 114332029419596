import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { Grid, OutlinedInput, Box, Chip, MenuItem, Select } from '@material-ui/core'
import { components, useServices, DataFlattener, useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import TprApiUrls from '../../apiUrls/TprApiUrls'
import BillingConfigKeys from 'src/constants/locale/key/BillingConfig'
import ValidationSchema from './ValidationSchema'

const {
    form: { field: { CngTextField, CngSelectField, CngCodeMasterAutocompleteField } },
    CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
    chargeCode: "",
    msgFunction: "",
    moduleName: "",
    submissionType: "",
    docType: "",
    status: "",
    partyIdList: ""
})

const FORMIK_PROPS = {
    initialValues: { ...DEFAULT_INITIAL_VALUES },
    makeValidationSchema: ValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap }) {
    const { securedSendRequest } = useServices();
    const { setValue, getValues } = useFormContext();
    const { translate } = useTranslation([Namespace.UI_COMPONENT, Namespace.BILLING_CONFIG])
    const translatedTextsObject = makeTranslatedTextsObject()

    function makeTranslatedTextsObject() {
        let chargeCode = translate(
            Namespace.BILLING_CONFIG,
            BillingConfigKeys.CHARGE_CODE
        )
        let msgFunction = translate(
            Namespace.BILLING_CONFIG,
            BillingConfigKeys.MSG_FUNCTION
        )
        let moduleName = translate(
            Namespace.BILLING_CONFIG,
            BillingConfigKeys.MODULE_NAME
        )
        let submissionType = translate(
            Namespace.BILLING_CONFIG,
            BillingConfigKeys.SUBMISSION_TYPE
        )
        let docType = translate(
            Namespace.BILLING_CONFIG,
            BillingConfigKeys.DOC_TYPE
        )
        let status = translate(
            Namespace.BILLING_CONFIG,
            BillingConfigKeys.STATUS
        )

        return {
            chargeCode,
            msgFunction,
            moduleName,
            submissionType,
            docType,
            status
        }
    }

    function fetchAllParty(setParty, securedSendRequest) {
        function onSuccess(response) {
            let content = response.data.content;
            let arr = [];

            content.forEach((e) => {
                let data = { text: e.name, value: e.id };
                arr.push(data);
            })
            // Set party list from registration
            setParty(arr)

            // Initialize selected values
            let existingArr = getValues("partyIdList") ? getValues("partyIdList").split(',') : [];
            let intArr = [];
            existingArr.forEach(e => intArr.push(parseInt(e)))
            setSelectedParty(intArr)

        }

        function onError(error) {
            console.log(error);
        }

        securedSendRequest.execute('POST', TprApiUrls.GET, {}, onSuccess, onError, () => { }, {}, null);
    }

    const [party, setParty] = useState([]);
    useEffect(() => { fetchAllParty(setParty, securedSendRequest) }, []);

    const [selectedParty, setSelectedParty] = useState([]);
    const partyChangeHandler = (event) => {
        const { target: { value } } = event;
        setSelectedParty(value);
        setValue("partyIdList", processPartyArray(value), { shouldValidate: true })
    };

    function processPartyArray(arr) {
        let result = null;

        for (let i = 0; i < arr.length; i++) {
            if (i == 0) {
                result = arr[i]
            } else {
                result += ',' + arr[i];
            }
        }
        return result;
    }

    return (
        <Grid container spacing={3}>
            <CngGridItem sm={12} md={6}>
                <CngTextField name="chargeCode" required
                    label={translatedTextsObject.chargeCode}
                    inputProps={{ maxLength: 40 }}
                />
            </CngGridItem>
            <CngGridItem sm={12} md={6}>
                <CngTextField name="msgFunction"
                    label={translatedTextsObject.msgFunction}
                    inputProps={{ maxLength: 5 }}
                />
            </CngGridItem>
            <CngGridItem sm={12} md={6}>
                <CngCodeMasterAutocompleteField
                    required
                    name="moduleName"
                    label={translatedTextsObject.moduleName}
                    codeType='BILLING_MODULE'
                />
            </CngGridItem>
            <CngGridItem sm={12} md={6}>
                <CngCodeMasterAutocompleteField
                    name="submissionType"
                    required
                    label={translatedTextsObject.submissionType}
                    codeType='CHARGE_AS_CODE'
                />
            </CngGridItem>
            <CngGridItem sm={12} md={6}>
                <CngTextField name="docType" required
                    label={translatedTextsObject.docType}
                    inputProps={{ maxLength: 50 }}
                    onChange={(e) => {
                        setValue("docType", e.target.value.toUpperCase(), { shouldValidate: true })
                    }}
                />
            </CngGridItem>
            <CngGridItem sm={12} md={6}>
                <CngCodeMasterAutocompleteField
                    name="status"
                    label={translatedTextsObject.status}
                    codeType='BILLING_STATUS'
                />
            </CngGridItem>
            <CngGridItem sm={12} md={6}>
                <CngSelectField
                    name="partyIdList"
                    label="Party"
                    items={party}
                    value={selectedParty}
                    onChange={partyChangeHandler}
                    SelectProps={{
                        multiple: true,
                        renderValue: (selected) => (
                            <Box style={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => {
                                    let filteredParty = party.filter(e => e.value == value)

                                    return (
                                        <Chip key={value} label={filteredParty[0].text} />
                                    )
                                })}
                            </Box>
                        )
                    }}

                />
            </CngGridItem>
        </Grid>
    )
}

function toClientDataFormat(serverData) {
    let localData = DataFlattener.parse(serverData);
    return localData;
}

function toServerDataFormat(localData) {
    return DataFlattener.unflatten(localData);
}

const FormProperties = Object.freeze({
    formikProps: FORMIK_PROPS,
    Fields: Fields,
    toClientDataFormat: toClientDataFormat,
    toServerDataFormat: toServerDataFormat
})

export default FormProperties