import { components } from 'cng-web-lib'
import { useFormikContext } from 'formik'
import React, { useState } from 'react'
import Axios from 'axios'
import { useHistory } from 'react-router-dom'

const {
  button: { CngPrimaryButton, CngSecondaryButton },
  CngGridItem, CngDialog
} = components

function DBAdminButtonSection({ buttonLabel, url, data, isAdd, showNotification, redirect }) {
  //Get the current modified form data using Formik
  var values = useFormikContext().values
  if (!isAdd) {
    //Get the saved form Data from Server
    var serverData = data.current
    //Copy the modified data in form to Server Data - this ensures ID and other basic information is copied
    values = { ...serverData, ...values }
  }

  const { setSubmitting, validateForm, isSubmitting, setErrors, setTouched } = useFormikContext()
  const [openDialog, setOpenDialog] = useState()
  const history = useHistory()

  function getTouchedObj(errors) {
    const touched = {}
    Object.keys(errors).map(key => {
      if (Array.isArray(errors[key])) {
        errors[key].map((val, index) => {
          if (index == 0) touched[key] = [];
          touched[key].push(getTouchedObj(val));
        });
      } else {
        touched[key] = true;
      }
    });

    return touched;
  };

  return (
    <CngGridItem>
      <CngPrimaryButton disabled={isSubmitting} isSubmitting={isSubmitting} onClick={() => {
        validateForm().then(
          (errors) => {
            if (Object.keys(errors).length === 0 && errors.constructor === Object) {
              setOpenDialog(true)
            } else {
              setErrors(errors)
              setTouched(getTouchedObj(errors))
            }
          }
        ).catch(
          () => {
            console.log('Catch Block')
          }
        ).then(() => {
          setSubmitting(false)
        })
      }} >
        {buttonLabel}
      </CngPrimaryButton>
      <CngDialog
        dialogAction={
          <>
            <CngSecondaryButton onClick={() => setOpenDialog(false)}>
              Close
            </CngSecondaryButton>
            <CngPrimaryButton onClick={() => {
              setSubmitting(true)
              const instance = Axios.create({
                withCredentials: false,
                headers: {
                  'Authorization': 'Bearer ' + sessionStorage.getItem("userToken"),
                }
              });
              instance.post(url, values).then((result) => {
                var data = result.data
                var errorMessages = data.errorMessages
                if (result.status == 200 && data != null && errorMessages == null) {
                  showNotification("success", "Status updated")
                  setSubmitting(false)
                  history.push(redirect)
                } else {
                  showNotification("error", errorMessages)
                  setSubmitting(false)
                }
              }, (error) => {
                console.log(error);
              });
              setOpenDialog(false);
            }}>
              {buttonLabel}
            </CngPrimaryButton>
          </>
        }
        dialogContent='Are you sure you want to update the status of record?'
        dialogTitle='Update Status'
        open={openDialog}
        onClose={() => setOpenDialog(false)}
      />
    </CngGridItem>
  )
}
export default DBAdminButtonSection
