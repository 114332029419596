import { Container, Grid, Paper } from '@material-ui/core'
import React, { useState } from 'react'
import { components, useTranslation } from 'cng-web-lib'
import ArrivalAddForm from './ArrivalAddPage'
import ArrivalEditForm from './ArrivalEditPage'
import Namespace from 'src/constants/locale/Namespace'
import ArrivalKeys from 'src/constants/locale/key/Arrivalmessage';
import withFormState from 'src/components/aciacehighway/hocs/withFormState'

import {
  constants,
} from 'cng-web-lib'

const {
  locale: {
    key: {
      UiComponentKeys,
    },
  },
} = constants

const { CngTabs } = components

function AddPage({ history, showNotification, onSetLoading }) {
  const [lockedOnFirstTab, setLockedOnFirstTab] = useState(true)
  const [arrivalId, setArrivalId] = useState('')
  const { translate } = useTranslation([
    Namespace.ARRIVALMESSAGE,
    Namespace.UI_COMPONENT
  ])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let arrivalMessage = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalKeys.TITLE
    )
    let arrivalResponse = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalKeys.ArrivalResponse.TITLE
    )
    let tabLockedMessage = translate(
      Namespace.UI_COMPONENT,
      UiComponentKeys.Tabs.PLEASE_SUBMIT_FIRST,
      {
        title: arrivalMessage
      }
    )

    return {
      arrivalMessage,
      arrivalResponse,
      tabLockedMessage
    }
  }

  function makeArrivalForm() {
    if (arrivalId === '') {
      return (
        <ArrivalAddForm
          history={history}
          showNotification={showNotification}
          onSetLoading={onSetLoading}
          onPostSubmitSuccess={(datum) => {
            setArrivalId(datum.id)
            setLockedOnFirstTab(false)
          }}
        />
      )
    } else {
      return (
        <ArrivalEditForm
          showNotification={showNotification}
          id={arrivalId}
        />
      )
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngTabs
          headerColor='primary'
          tabs={[
            {
              tabName: translatedTextsObject.arrivalMessage,
              tabContent: makeArrivalForm()
            },
          ]}
        />
      </Grid>
    </Grid>
  )
}

export default withFormState(AddPage)