import React, { useState } from 'react';
import { components,useServices } from 'cng-web-lib'
import { DettachAll, SubmitAll,SubmitMultiplefromManifest } from 'src/common/NACommon'


const {
    button: { CngPrimaryButton, CngSecondaryButton },
    CngDialog
  } = components


function ConfirmationDialog({
    url,
    openDialog,
    setOpenDialog,
    onCancel,
    cngTableRef,
    cancelButtonName,
    submitButtonName,
    rowData,
    title,
    message,
    showNotification,
    successMsg,
    errorMsg,
    isDettach,
    isMultipleSubmit,
    history,
    setIsLoading
  }){
    const {securedSendRequest } = useServices();
    return (
      <>
      <CngDialog
        fullWidth
        maxWidth='sm'
        dialogAction={
          <>
            <CngSecondaryButton onClick={() => {
              setOpenDialog(false) 
              setIsLoading(false)}}>
              {cancelButtonName}
            </CngSecondaryButton>
            {(isDettach) && ( //for dettach
              <CngPrimaryButton onClick={() => {
                setOpenDialog(false)
                setIsLoading(true)
                DettachAll(url, rowData,showNotification, successMsg,setIsLoading,securedSendRequest)
              }}>
                {submitButtonName}
              </CngPrimaryButton>
            )}

            {(isDettach!==true) && (isMultipleSubmit!==true) &&( // for submit
            <CngPrimaryButton onClick={() => {
              setOpenDialog(false)
              SubmitAll(url, rowData, showNotification, cngTableRef, successMsg, errorMsg,securedSendRequest)
            }}>
              {submitButtonName}
            </CngPrimaryButton>
             )}

           {(isMultipleSubmit===true) && ( // to submit cargo from manifest
              <CngPrimaryButton onClick={() => {
                setOpenDialog(false)
                setIsLoading(true)
                SubmitMultiplefromManifest(url, rowData, showNotification, successMsg,setIsLoading,history,errorMsg,securedSendRequest)
              }}>
                {submitButtonName}
              </CngPrimaryButton>
            )}
          </>
        }
        dialogContent={message}
        dialogTitle={title}
        open={openDialog}
        onClose={onCancel}
      />
    </>
    )
}

export default ConfirmationDialog
